import Footer from '@/Components/Footer';
import Header from '@/Components/Header';
import { ModalProvider } from '@/modals/provider';
import Providers from '@/providers';
import { PrimeReactProvider } from 'primereact/api';
import { PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';

export default function HeaderLayout({ children }: PropsWithChildren) {
    return (
        <>
            <PrimeReactProvider>
                <Providers>
                    <ModalProvider>
                        <Header />
                        <main className="main-height container-xs relative flex items-start justify-center">
                            {children}
                        </main>
                        <Footer />
                    </ModalProvider>
                </Providers>
            </PrimeReactProvider>
            <ToastContainer />
        </>
    );
}
