import { Button, Img } from './..';

interface Props {
    className?: string;
}

export default function Header({ className = '', ...props }: Props) {
    return (
        <header
            {...props}
            className={`${className} pink_500_pink_A100_border sticky top-0 z-50 flex items-center justify-center border-b border-solid bg-gray-900 py-[30px] sm:py-4`}
        >
            <div className="container-xs flex justify-center">
                <div className="flex w-full items-center justify-between gap-5">
                    <a href="/">
                        <Img
                            src="img_header_logo.svg"
                            width={156}
                            height={50}
                            alt="Headerlogo"
                            className="h-[50px] w-[156px] object-contain sm:w-[130px]"
                        />
                    </a>
                    <a
                        href="mailto:hello@polkaidentity.com"
                        className="w-full max-w-[194px]"
                    >
                        <Button
                            shape="round"
                            color="pink_500_pink_A100"
                            className="w-full whitespace-nowrap bg-gradient bg-clip-text text-transparent lg:text-[17px] sm:px-4"
                        >
                            Contact Us
                        </Button>
                    </a>
                </div>
            </div>
        </header>
    );
}
